import * as paths from "./paths";

import { Route, Switch } from "react-router-dom";

import BrandedTopBar from "../components/topbar/BrandedTopBar";
import HomeTopBar from "../components/topbar/HomeTopBar";
import React from "react";

export default () => (
  <Switch>
    <Route path={paths.MENU} exact component={BrandedTopBar} />
    <Route path={paths.LOCATION} exact component={BrandedTopBar} />
    <Route path={paths.HOME} exact component={HomeTopBar} />
  </Switch>
);
