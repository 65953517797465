import CenterButton from "../misc/CenterButton";
import React from "react";

export default () => {
  const btnProps = {
    variant: "contained",
    color: "primary",
    href:
      "https://www.google.com/maps/place/Chen's+Nudelbar/@48.1371449,11.574001,15z/data=!4m2!3m1!1s0x0:0xe332a71abcb11df6?sa=X&ved=2ahUKEwjIivO6x-DnAhVyWxUIHXnBAocQ_BIwCnoECBMQCA",
    target: "_blank",
    children: "Auf Googlemaps Öffnen"
  };
  return <CenterButton {...btnProps} />;
};
