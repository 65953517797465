import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { address, email, tel } from "./locationTexts";

import Box from "@material-ui/core/Box";
import { Divider } from "@material-ui/core";
import ImpressumBtn from "./ImpressumBtn";
import MapsBtn from "./MapsBtn";
import PageTitle from "../navigation/PageTitle";
import React from "react";
import TextBlock, { OpeningHours } from "../misc/TextBlock";
import buttonBg from "../../images/backBg.jpg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topImg: {
      maxWidth: "600px",
      paddingTop: "20%",
      backgroundImage: `url(${buttonBg})`,
    },
    button: {
      width: "66%",
      position: "relative",
      bottom: "50%",
      left: "50%",
    },
  })
);
export default () => {
  const classes = useStyles();

  return (
    <>
      <PageTitle>Kontakt</PageTitle>
      <Box className={classes.topImg} />
      <TextBlock {...address} />
      <MapsBtn />
      <Divider variant="middle" />
      <OpeningHours />
      <Divider variant="middle" />
      <TextBlock {...tel} />
      <Divider variant="middle" />
      <TextBlock {...email} />
      <Divider variant="middle" />
      <ImpressumBtn />
    </>
  );
};
