import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { IDish } from "./menuData";
import React from "react";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        margin: theme.spacing(1),
      },
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
  })
);

interface IDishProps {
  dish: IDish;
}

const formatDishPrice = (price: number | [string, number][]): any => {
  if (typeof price === "number") {
    return price.toString();
  } else {
    return (
      <>
        {price.map((price) => (
          <>
            {price[0]} {price[1]} <br />
          </>
        ))}
      </>
    );
  }
};

export default function DishCard(props: IDishProps) {
  const classes = useStyles();
  const { dish } = props;

  return (
    <Card className={classes.root}>
      {dish.img && (
        <CardMedia
          className={classes.media}
          image={dish.img}
          title={dish.title}
        />
      )}
      <CardContent>
        <Typography variant="h5" component="h3">
          {dish.title}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {dish.subtitle}
        </Typography>
        <Typography
          variant="caption"
          align="right"
          component="div"
          color="textSecondary"
        >
          {formatDishPrice(dish.price)}
        </Typography>
      </CardContent>
    </Card>
  );
}
