import { contact, courtNo, provider, taxNo } from "./impressumTexts";

import { Divider } from "@material-ui/core";
import PageTitle from "../navigation/PageTitle";
import React from "react";
import TextBlock from "../misc/TextBlock";

export default () => {
  return (
    <>
      <PageTitle>Impressum</PageTitle>
      <TextBlock {...provider} />
      <Divider variant="middle" />
      <TextBlock {...contact} />
      <Divider variant="middle" />
      <TextBlock {...courtNo} />
      <Divider variant="middle" />
      <TextBlock {...taxNo} />
      <Divider variant="middle" />
    </>
  );
};
