import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { Box } from "@material-ui/core";
import React from "react";
import Typography from "@material-ui/core/Typography";
import bgImg from "../home/images/s42.jpg";
import logo from "../../static/images/icons/brand.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      width: 96,
      height: 96,
      margin: "auto"
    },
    background: {
      height: "33vh",
      maxWidth: "600px",
      margin: "auto",
      backgroundImage: `url(${bgImg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      opacity: 0.3
    },
    brandName: {
      borderTop: "1px solid black",
      borderBottom: "1px solid black"
    },
    textBox: {
      height: "33vh",
      maxWidth: "600px",
      position: "absolute",
      left: "50%",
      top: 0,
      transform: "translate(-50%, 0)"
    }
  })
);

export default () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.background}></Box>
      <Box
        className={classes.textBox}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.brandName}
        >
          <img src={logo} className={classes.logo} alt="Logo" />
          <Typography variant="h2" component="h1" align="center">
            Chen's Nudelbar
          </Typography>
        </Box>
      </Box>
    </>
  );
};
