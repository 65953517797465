import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { Box } from "@material-ui/core";
import React from "react";
import Typography from "@material-ui/core/Typography";
import logo from "../../static/images/icons/brand.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      width: 56,
      height: 56
    },
    root: {
      margin: "auto",
      height: 72,
      padding: theme.spacing(1),
      borderBottom: "1px solid grey"
    }
  })
);

export default () => {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <img src={logo} className={classes.logo} alt="Logo" />
      <Typography variant="h6" component="h2" align="center">
        Chen's Nudelbar
      </Typography>
    </Box>
  );
};
