import * as paths from "./paths";

import { Route, Switch } from "react-router-dom";

import Home from "../components/home/Home";
import Impressum from "../components/impressum/Impressum";
import Location from "../components/location/Location";
import Menu from "../components/menu/Menu";
import NotFound from "../components/errors/NotFound";
import React from "react";

export default () => (
  <Switch>
    <Route path={paths.HOME} exact component={Home} />
    <Route path={paths.MENU} exact component={Menu} />
    <Route path={paths.LOCATION} exact component={Location} />
    <Route path={paths.IMPRESSUM} exact component={Impressum} />
    <Route component={NotFound} />
  </Switch>
);
