import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import React from "react";
import { Typography } from "@material-ui/core";

interface SectionTitleProps {
  title: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
      borderBottom: "2px solid grey",
      paddingBottom: "4px",
      width: "66%"
    }
  })
);

export default function SectionTitle(props: SectionTitleProps) {
  const classes = useStyles();

  return (
    <Typography variant="h3" component="h2" className={classes.root}>
      {props.title}
    </Typography>
  );
}
