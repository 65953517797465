import CenterButton from "../misc/CenterButton";
import { IMPRESSUM } from "../../router/paths";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export default () => {
  const btnProps = {
    children: "Impressum",
    component: RouterLink,
    to: IMPRESSUM
  };
  return <CenterButton {...btnProps} />;
};
