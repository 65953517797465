import { ITextBlock } from "../misc/TextBlock";

export const address: ITextBlock = {
  title: "Adresse",
  texts: [
    "1. Untergeschoss",
    "Galeria Kaufhof Marienplatz",
    "Kaufingerstr. 1-7",
    "80331 München",
  ],
};

export const email: ITextBlock = {
  title: "Email",
  texts: ["chennudelbar@gmail.com"],
};

export const tel: ITextBlock = {
  title: "Telefon",
  texts: ["+49 89 95879333"],
};
