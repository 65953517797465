import {
  MuiThemeProvider,
  Theme,
  createMuiTheme,
  responsiveFontSizes
} from "@material-ui/core/styles";

import BottomBar from "./components/navigation/BottomBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import PageContainer from "./components/navigation/PageContainer";
import React from "react";
import Routes from "./router/Routes";
import TopBar from "./router/TopBarRouter";
import grey from "@material-ui/core/colors/grey";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useCreateTheme = (darkMode: boolean) =>
  React.useMemo(
    () =>
      responsiveFontSizes(
        createMuiTheme({
          palette: {
            type: darkMode ? "dark" : "light",
            primary: {
              main: "#4cbd2d"
            },
            secondary: grey
          },
          typography: {
            fontFamily: [
              "Roboto",
              '"Helvetica Neue"',
              "Arial",
              "sans-serif",
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"'
            ].join(",")
          }
        })
      ),
    [darkMode]
  );

const useDarkMode = (): boolean =>
  useMediaQuery("(prefers-color-scheme: dark)");

export default () => {
  const darkMode: boolean = useDarkMode();
  const theme: Theme = useCreateTheme(darkMode);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <TopBar />
      <PageContainer>
        <Routes />
      </PageContainer>
      <BottomBar />
    </MuiThemeProvider>
  );
};
