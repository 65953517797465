import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { Divider } from "@material-ui/core";
import React from "react";
import Typography from "@material-ui/core/Typography";
import set from "./images/set.jpg";
import ziehen2 from "./images/ziehen2.jpg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      margin: theme.spacing(2)
    },
    impressumBtn: {
      position: "relative",
      right: 0
    },
    spaceTop: {
      marginTop: theme.spacing(4)
    },
    spaceBottom: {
      marginBottom: theme.spacing(4)
    }
  })
);

export default function Home() {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h2" className={classes.text} align="center">
        Authentische Chinesische Küche
      </Typography>
      <Divider variant="middle" />
      <Typography
        variant="h4"
        component="h3"
        className={classes.text}
        align="center"
      >
        Täglich frische handgezogene Nudeln
      </Typography>
      <img alt="A person pulling noodles." src={ziehen2} width="100%" />
      <Typography
        variant="h6"
        component="h2"
        className={classes.spaceTop}
        align="center"
      >
        <b>Eine einmalige Reise durch China</b>
      </Typography>
      <Divider variant="middle" />
      <Typography
        variant="h5"
        component="h2"
        className={classes.spaceBottom}
        align="center"
      >
        Regelmäßig wechselnde Spezialitäten
      </Typography>
      <img alt="A person pulling noodles." src={set} width="100%" />
      <Divider variant="middle" className={classes.spaceTop} />
      <Typography variant="h5" component="h2" align="center">
        <b>Tradition</b>
      </Typography>
      <Typography
        variant="h5"
        component="h2"
        align="center"
        color="textSecondary"
      >
        trifft
      </Typography>
      <Typography variant="h5" component="h2" align="center">
        <b>Moderne</b>
      </Typography>
      <Divider variant="middle" className={classes.spaceBottom} />
    </>
  );
}
