import DishCard from "./DishCard";
import { Grid } from "@material-ui/core";
import { IDish } from "./menuData";
import React from "react";
import SectionTitle from "./SectionTitle";

interface IMenuSectionProps {
  section: {
    dishes: IDish[];
    title: string;
  };
}

export default function MenuSection(props: IMenuSectionProps) {
  const { section } = props;

  const renderDishes = () => (
    <Grid container>
      {section.dishes.map(dish => (
        <Grid item sm={6} xs={12} key={dish.title}>
          <DishCard dish={dish} />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <div>
      <SectionTitle title={section.title} />
      {renderDishes()}
    </div>
  );
}
