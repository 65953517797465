import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: "auto",
      marginRight: "auto",
      width: "66%",
      display: "block",
      textAlign: "center"
    }
  })
);

export default (props: any) => {
  const classes = useStyles();
  return <Button {...props} className={classes.button} />;
};
