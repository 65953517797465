import { ITextBlock } from "../misc/TextBlock";

export const provider: ITextBlock = {
  title: "Anbieter",
  texts: [
    "Chen UG (Haftungsbeschränkt)",
    "Vertreten durch den Geschätsführer Xiaobo Chen",
    "Kaisermantelstr. 28",
    "80939 München"
  ]
};

export const contact: ITextBlock = {
  title: "Kontakt",
  texts: ["chennudelbar@gmail.com", "+49 (0) 151 10954317"]
};

export const courtNo: ITextBlock = {
  title: "Handelsregister",
  texts: ["Amtsgericht München HRB 237699"]
};

export const taxNo: ITextBlock = {
  title: "Steuernummer",
  texts: ["St.-Nr. 143/124/42125", "USt-IdNr. DE316653480"]
};
