import b10 from "./images/b10.jpg";
import b11 from "./images/b11.jpg";
import b12 from "./images/b12.jpg";
import b2 from "./images/b2.jpg";
import b3 from "./images/b3.jpg";
import b5 from "./images/b5.jpg";
import b6 from "./images/b6.jpg";
import b7 from "./images/b7.jpg";
import b8 from "./images/b8.jpg";
import b9 from "./images/b9.jpg";
import g1 from "./images/g1.jpg";
import g2 from "./images/g2.jpg";
import g4 from "./images/g4.jpg";
import g5 from "./images/g5.jpg";
import g6 from "./images/g6.jpg";
import jzk from "./images/jzk.jpg";
import r1 from "./images/r1.jpg";
import r2 from "./images/r2.jpg";
import r3 from "./images/r3.jpg";
import s1 from "./images/s1.jpg";
import s2 from "./images/s2.jpg";
import s3 from "./images/s3.jpg";
import s5 from "./images/s5.jpg";
import wtk from "./images/wtk.jpg";

export interface IDish {
  img?: string;
  price: number | [string, number][];
  title: string;
  description?: string;
  subtitle?: string;
}

export interface IDishSection {
  title: string;
  subtitle?: string;
  dishes: IDish[];
}

interface IMenu {
  sections: IDishSection[];
}

export const menu: IMenu = {
  sections: [
    {
      title: "Nudelsuppen",
      dishes: [
        {
          img: s1,
          price: 13.8,
          title: "Langzhou Lamian",
          subtitle: "mit Rindfleisch, Rettich u. Korriander",
        },
        {
          img: s2,
          price: 14.8,
          title: "Hongshao Lamian",
          subtitle:
            "zarte rotgeschmorte Rindfleischstückchen mit leicht-scharfer Soße, dazu frischem Gemüse und Tee-Ei",
        },
        {
          img: s3,
          price: 13.8,
          title: "Paigu Lamian",
          subtitle: "rotegeschmorte Rippchen mit frischem Gemüse und Tee-Ei",
        },
        {
          img: s5,
          price: 11.8,
          title: "Vegetarische Lamiansuppe",
          subtitle:
            "mit Tofu, bunte Gemüsemischung u. Tee-Ei (vegane Variante ohne Tee-Ei)",
        },
      ],
    },
    {
      title: "Nudelgerichte",
      dishes: [
        {
          img: g1,
          price: 12.8,
          title: "Lamian mit Tomaten-Rührei Soße",
        },
        {
          img: g2,
          price: 13.8,
          title: "Lamian mit Hühnerhackfleisch",
          subtitle: "und Kräuterseitlinge",
        },
        {
          img: g4,
          price: 11.8,
          title: "Sichuan Lamian (vegan)",
          subtitle:
            "feurig-scharf und voller Aroma, Sichuan-Pfeffermischung, Chilli-Knoblauch-Ingwersoße, eigelegtem Senfkohl u. zeriebene Ergnüsse",
        },
        {
          img: g5,
          price: 11.8,
          title: "Lamian mit Erdnussoße (vegan)",
          subtitle:
            "Chinesische Erdnusssoße mit Sichuanpfeffer, Tofu u. Gemüse",
        },
        {
          img: g6,
          price: [
            ["mit Schrimps und Gemüse", 16.8],
            ["mit Gemüse", 11.8],
            ["mit Hühnchen und Gemüse", 12.8],
          ],
          title: "Gebratene Nudeln",
        },
      ],
    },
    {
      title: "Teigtaschen",
      dishes: [
        {
          title: "Jiaozi",
          img: jzk,
          price: [
            ["5 stk.", 6.8],
            ["12 stk", 13.8],
          ],
        },
        {
          img: wtk,
          title: "Wantan Suppe",
          price: [
            ["5 stk", 6.8],
            ["12 stk", 13.8],
          ],
        },
      ],
    },
    {
      title: "Reisgerichte",
      dishes: [
        {
          img: r1,
          price: 12.8,
          title: "Hühnerschenkelfleisch",
          subtitle: "in traditioneller dunkler süß-sauer Soße",
        },
        {
          img: r2,
          price: 16.8,
          title: "Beijing Ente",
          subtitle:
            "nach original Beijinger Art im Ofen gegrillt und anschließend ausgebacken, dazu Pfannengemüse",
        },
        {
          img: r3,
          price: 20.8,
          title: "Gegrirllter Aal",
          description: "",
        },
      ],
    },
    {
      title: "Beilagen",
      dishes: [
        {
          img: b2,
          price: 4.5,
          title: "Chinesischer Gurkensalat",
        },
        {
          img: b3,
          price: 4.5,
          title: "Seetang Salat",
        },
        {
          img: b5,
          price: 7.5,
          title: "Rindfleischsalat",
          subtitle: "mit Koriander",
        },
        {
          img: b6,
          price: 9.5,
          title: "Nanjing Salzente",
        },
        {
          img: b7,
          price: 6.0,
          title: "Gebratene Buschbohnen",
          subtitle: "mit getrocknetem Pachoi",
        },
        {
          img: b8,
          price: 6.0,
          title: "Gebratene Peperoni",
        },
        {
          img: b9,
          price: 6.0,
          title: "Ausgebackene Bio-Tofu",
        },
        {
          img: b10,
          price: 6.5,
          title: "Knusprige Hühnerflügel",
          subtitle: "mit Kreuzkümmel",
        },
        {
          img: b11,
          price: 10.5,
          title: "Beijing Ente",
          subtitle: "im Ofen gegrillt und anschließend ausgebacken",
        },
        {
          img: b12,
          price: 5,
          title: "Zhong Zi",
          subtitle:
            "Klebereis mit Schweinefleisch, eingewickelt im Bambusblatt",
        },
      ],
    },
  ],
};
