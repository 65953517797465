import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import React from "react";
import Typography from "@material-ui/core/Typography";

export interface ITextBlock {
  title: string;
  texts: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
    },
    paragraph: {
      marginBottom: theme.spacing(2),
    },
  })
);

export default (props: ITextBlock) => {
  const classes = useStyles();
  const { title, texts } = props;

  const renderTexts = (texts: string[]): JSX.Element[] =>
    texts.map((text: string, index: number) => (
      <Typography variant="body1" component="div" align="center" key={index}>
        {text}
      </Typography>
    ));

  return (
    <div className={classes.root}>
      <Typography variant="h5" component="h2" align="center">
        {title}
      </Typography>
      {renderTexts(texts)}
    </div>
  );
};

export function OpeningHours() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h5" component="h2" align="center">
        Öffnungszeiten
      </Typography>
      <Typography
        variant="body1"
        component="div"
        align="center"
        className={classes.paragraph}
      >
        Mo. - Sa.
        <br />
        11:00 - 20:00
        <br />
        <strong>Küche bis 19:00</strong>
      </Typography>
      <Typography variant="body1" component="div" align="center">
        Sonn- u. Feiertage
        <br />
        geschlossen
      </Typography>
    </div>
  );
}
