import { Container } from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface PageContainerProps {
  children: any;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    marginBottom: theme.spacing(8)
  }
}));

export default (props: PageContainerProps) => {
  const classes = useStyles();

  return (
    <Container {...{ maxWidth: "sm", ...props }} className={classes.root} />
  );
};
