import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import MenuSection from "./MenuSection";
import PageTitle from "../navigation/PageTitle";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { menu } from "./menuData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      margin: theme.spacing(2)
    }
  })
);

export default function Menu() {
  const classes = useStyles();

  const renderMenu = () =>
    menu.sections.map(section => (
      <MenuSection section={section} key={section.title} />
    ));

  return (
    <>
      <PageTitle key="pageTitle">Speisekarte</PageTitle>
      <Typography variant="body1" align="justify" className={classes.text}>
        Im folgenden finden Sie eine Auswahl unserer Speisekarte. Für
        wiederkehrende Kunden bieten wir ständig wechselnde Spezialgerichte an.
        Fragen Sie unser Servicepersonal vor Ort nach Tagesgerichte. Allergene
        und Zusatzstoffe, sowie finale Preisliste finden Sie vor Ort in unserer
        Speisekarte.
      </Typography>
      <Typography variant="body1" align="justify" className={classes.text}>
        Wir freuen uns Sie bald bei uns begrüßen zu dürfen.
      </Typography>
      {renderMenu()}
    </>
  );
}
