import * as paths from "../../router/paths";

import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import React from "react";
import history from "../../history";
import menuIcon from "../../static/images/icons/menu_light.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      position: "fixed",
      bottom: 0,
      backgroundColor: theme.palette.secondary.main
      // color: theme.palette.text.primary
      // "&$selected": {
      //   color: theme.palette.primary.text.primary
      // }
    },
    navigationAction: {
      color: theme.palette.secondary.contrastText,
      "&$selected": {
        color: theme.palette.secondary.main
      }
    }
  })
);

// export const styles = theme => ({
//   /* Styles applied to the root element. */
//   root: {
//     color: theme.palette.text.secondary,
//     "&$selected": {
//       color: theme.palette.primary.main
//     }
//   },
//   /* Styles applied to the root element if selected. */
//   selected: {}
// });

interface page {
  label: string;
  path: string;
  icon: any;
}

const pages: page[] = [
  { label: "Home", path: paths.HOME, icon: <HomeIcon /> },
  {
    label: "Speisekarte",
    path: paths.MENU,
    icon: (
      <img
        style={{
          width: 24,
          height: 24
        }}
        src={menuIcon}
        alt="Menu Icon"
      />
    )
  },
  {
    label: "Kontakt",
    path: paths.LOCATION,
    icon: <LocationOnIcon />
  }
];

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState();

  React.useEffect(() => {
    const path = window.location.pathname;
    const found = pages.find(page => page.path === path);
    if (found) {
      setValue(path);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent, value: string) => {
    setValue(value);
    history.push(value);
  };

  const renderPages = (): any[] =>
    pages.map(page => (
      <BottomNavigationAction
        label={page.label}
        value={page.path}
        icon={page.icon}
        key={page.label}
        className={classes.navigationAction}
      />
    ));

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      className={classes.root}
    >
      {renderPages()}
    </BottomNavigation>
  );
}
